import { useEffect, useRef, useState } from "react";

import { addMinutes, differenceInMilliseconds, format } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../../components/SolidButton";
import { showToast } from "../../../../../../components/CustomToast";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";
import validateFormFields from "../../../../../../utils/validateFormFields";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";

import { IAuthFormData } from "../../dtos";

import {
  Container,
  InputLine,
  InputContainer,
  Footer,
  StepTitle,
  NotRobotLine,
  RegisterForm,
} from "./styles";

interface StepOneProps {
  validatingEmail: boolean;
  setEmailValidation: React.Dispatch<React.SetStateAction<boolean>>;
  formData: IAuthFormData | undefined;
  nextStep: () => void;
  prevStep: () => void;
  submitCode: (code: string) => void;
  deleteCode: () => void;
  resendCode: () => void;
  validateEmail: () => void;
  setFormData: any;
  loading: boolean;
}

let COUNTDOWN_TIMEOUT: NodeJS.Timeout;

export function StepOne({
  formData,
  setFormData,
  nextStep,
  prevStep,
  validatingEmail,
  setEmailValidation,
  loading,
  submitCode,
  deleteCode,
  resendCode,
  validateEmail,
}: StepOneProps) {
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);
  const ref6 = useRef<any>(null);

  const [errors, setErrors] = useState({} as any);
  const [timer, setTimer] = useState("");
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [codeExpired, setCodeExpired] = useState(false);
  const [reCaptcha, setReCaptcha] = useState<any>();

  const [emailCode, setEmailCode] = useState([
    {
      value: "",
      ref: ref1,
    },
    {
      value: "",
      ref: ref2,
    },
    {
      value: "",
      ref: ref3,
    },
    {
      value: "",
      ref: ref4,
    },
    {
      value: "",
      ref: ref5,
    },
    {
      value: "",
      ref: ref6,
    },
  ]);

  async function validateEmailAndName(
    event?: React.FormEvent<HTMLFormElement>
  ) {
    event?.preventDefault();

    try {
      const body = {
        email: formData?.email,
        password: formData?.password,
        password_confirmation: formData?.password_confirmation,
      };

      const emailAndNameSchema = Yup.object().shape({
        email: Yup.string()
          .email(typesErrors.validEmail)
          .required(typesErrors.required),
        password: Yup.string()
          .required(typesErrors.required)
          .matches(
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).+$/,
            typesErrors.passwordCharacters
          )
          .min(8, typesErrors.passwordLength),
        password_confirmation: Yup.string()
          .required(typesErrors.required)
          .oneOf([Yup.ref("password"), null], typesErrors.matchPassword),
      });

      const validation = await validateFormFields(body, emailAndNameSchema, {
        email: "",
        password: "",
        password_confirmation: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        return;
      }

      setErrors({});

      validateEmail();

    } catch {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.emailInUse"),
      });
    }
  }

  async function validateCodes(event?: React.FormEvent<HTMLFormElement>) {
    event?.preventDefault();

    const body = {
      codes: emailCode.map((item) => item.value),
    };

    const emailAndNameSchema = Yup.object().shape({
      codes: Yup.array().of(Yup.string().required(typesErrors.minRequired)),
    });

    const validation = await validateFormFields(body, emailAndNameSchema, {
      codes: emailCode.map(() => {
        return "";
      }),
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    if (codeExpired) {
      setErrors({});
      showToast({
        type: "error",
        message: i18n.t("userRegistration.codeExpired"),
      });

      return;
    }

    setErrors({});
    submitCode(emailCode.map((item) => item.value).join(""));
  }

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  function resetDate() {
    setFinalDate(addMinutes(new Date(), 3));
    setCodeExpired(false);
  }

  function formatCountdown() {
    if (finalDate) {
      const difference = differenceInMilliseconds(finalDate, new Date());
      const differenceDate = new Date(difference);

      setTimer(format(differenceDate, "mm:ss"));
    }
  }

  useEffect(() => {
    if (timer !== "00:00") {
      COUNTDOWN_TIMEOUT = setTimeout(() => {
        formatCountdown();
      }, 1000);
    } else {
      setTimer("00:00");
      clearTimeout(COUNTDOWN_TIMEOUT);
      setCodeExpired(true);
    }
  }, [timer]);

  useEffect(() => {
    formatCountdown();
  }, [finalDate]);

  return (
    <Container
      onSubmit={(event) => {
        /*        if (validatingEmail) {
                  validateCodes(event);
                } else {
                  validateEmailAndName(event);
                }*/
        validateEmailAndName(event)
      }}
    >
      {/*{validatingEmail ? (
        <>
          <StepTitle>{`${i18n.t("userRegistration.fillCodes")}`}</StepTitle>
          <StepSubtitle>
            {`${i18n.t("userRegistration.codeExpires")}`} {timer}
          </StepSubtitle>
          <InputLine>
            {emailCode.map((item, index) => {
              return (
                <CodeInputContainer key={index}>
                  <CodeInput
                    ref={item.ref}
                    maxLength={1}
                    onPaste={(event) => {
                      const codes = event.clipboardData
                        .getData("text")
                        .split("");
                      if (codes.length === 6) {
                        const tmpEmailCode = emailCode.map((item, pos) => ({
                          ...item,
                          value: codes[pos],
                        }));

                        setEmailCode([...tmpEmailCode]);
                      }
                    }}
                    onChange={(event) => {
                      setEmailCode((prevstate) => {
                        const tmpCodes = [...prevstate];
                        tmpCodes[index].value =
                          event.target.value.toUpperCase();

                        return tmpCodes;
                      });

                      if (event.target.value === "") {
                        emailCode[index - 1]?.ref?.current?.focus();
                        return;
                      }
                      emailCode[index + 1]?.ref?.current?.focus();
                    }}
                    value={item.value}
                    error={errors?.codes ? errors?.codes[index] : ""}
                  />
                </CodeInputContainer>
              );
            })}
          </InputLine>
          <SendEmailText
            onClick={() => {
              resetDate();
              resendCode();
            }}
          >
            {`${i18n.t("userRegistration.sendCode")}`}
          </SendEmailText>
        </>
      )
        :
          */}
      <>
        <StepTitle>{`${i18n.t(
          "userRegistration.fillNameAndEmail"
        )}`}</StepTitle>

        <RegisterForm>
          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="email"
                label={`${i18n.t("userRegistration.email")}`}
                handleChange={(event) => {
                  setFormData((prevState: IAuthFormData) => ({
                    ...prevState,
                    email: event,
                  }));
                  clearError("email");
                }}
                value={formData?.email ?? ""}
                error={errors?.email}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="password"
                type="password"
                label={`${i18n.t("userRegistration.password")}`}
                handleChange={(event) => {
                  setFormData((prevState: IAuthFormData) => ({
                    ...prevState,
                    password: event,
                  }));
                  clearError("password");
                }}
                value={formData?.password ?? ""}
                error={errors?.password}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="password_confirmation"
                type="password"
                label={`${i18n.t("userRegistration.password_confirmation")}`}
                handleChange={(event) => {
                  setFormData((prevState: IAuthFormData) => ({
                    ...prevState,
                    password_confirmation: event,
                  }));
                  clearError("password_confirmation");
                }}
                value={formData?.password_confirmation ?? ""}
                error={errors?.password_confirmation}
              />
            </InputContainer>
          </InputLine>
        </RegisterForm>

        <NotRobotLine>
          {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => setReCaptcha(value)}
            />
          )}
        </NotRobotLine>
      </>

      <Footer>
        {validatingEmail && (
          <OutlinedButton
            buttonWidth={220}
            buttonHeight={42}
            text={`${i18n.t("userRegistration.prevStep")}`}
            onClick={() => {
              const tmpEmailCode = emailCode.map((item) => ({
                ...item,
                value: "",
              }));

              setEmailCode([...tmpEmailCode]);
              setFormData((prevState: IAuthFormData) => ({
                ...prevState,
                password: "",
                password_confirmation: "",
              }));
              deleteCode();
              setEmailValidation(false);
            }}
          />
        )}

        <SolidButton
          text={`${i18n.t("userRegistration.nextStep")}`}
          buttonWidth={220}
          buttonHeight={42}
          type="submit"
          loading={loading}
        // disabled={!reCaptcha}
        />
      </Footer>
    </Container>
  );
}
