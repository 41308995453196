import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 1rem;
  gap: 1rem;

  .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 0 0;
    color: var(--dark-gray);

    .newAddress {
      color: var(--fixit);
      display: flex;
      justify-content: flex-end;
      gap: 2.5px;
      strong {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
      span {
        font-size: 14px;
        color: var(--gray);
      }
    }
  }

  .addAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 2rem;
    color: var(--dark-gray);
  }
`;

export const InputContainer = styled.div``;

export const WarningLabel = styled.span`
  font-size: 12px;
  color: var(--error)
`;

export const RadioContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  .Mui-checked {
    color: var(--fixit) !important;
  }

  .MuiTypography-root {
    color: var(--dark-gray);
  }

  .label {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-gray);
    margin-top: 20px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 1rem 0 0;

  button {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 0 2rem 0 0;
`;

export const Label = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--gray);
`;

export const TotalValue = styled.div`
  font-size: 1.8rem;
  color: var(--active-green);
`;
export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  img{
    max-width: 70%;
    max-height: 70%
  }
`;

export const CopyPaste = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  word-break: break-word;
  word-wrap: break-word;
  font-size: 14px;
  color: var(--dark-gray);

  .Header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0 0.5rem;
    font-size: 1.3rem;
    font-weight: 500;

    svg {
      :hover {
        cursor: pointer;
      }
    }
  }
`;

export const SentToMailTextContainer = styled.div`
  font-size: 12px;
  color: var(--dark-gray);
`;

export const DueDateInformation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: var(--gray);
  width: 100%;
  font-weight: 500;

  .Fixcoin{
    color: var(--fixit);
    font-weight: 600;
  }
`;
