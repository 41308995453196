import { useNavigate } from "react-router-dom";
import { ClosureItem } from "../../../../components/ClosureItem";
import { i18n } from "../../../../localization/i18n";
import { Modal } from "../../../../components/Modal";
import { format } from "date-fns";
import { Bill, useAuth } from "../../../../hooks/AuthContext";
import { useEffect, useState } from "react";
import { currencyValue } from "../../../../components/UsersProfile/mock";
import api from "../../../../services/api";
import { BillPaymentDetails } from "../../../../components/BillPaymentDetails";
import { Container, Content } from "./styles";


export default function UserBills() {
  const { user, currency } = useAuth();
  const navigate = useNavigate();

  const [userBills, setUserBills] = useState<Bill[]>([])
  const [openCardSelectModal, setOpenCardSelectModal] = useState<Number | null>();


  function formatDate(date: Date) {
    return format(date, "dd/MM/yyyy");
  }

  function toggleCardSelectModal() {
    setOpenCardSelectModal(null);
  }


  function getDates(monthAndYear: string, isEndDate: boolean): Date {

    const splitedDate = monthAndYear.split('/').map(date => Number(date))

    return new Date(splitedDate[1], isEndDate ? splitedDate[0] - 1 : splitedDate[0] - 2, 26)
  }


  async function getLicenseeBills() {
    try {
      await api.get(`/bills`).then((response) => {
        console.log(response.data)
        setUserBills(response.data)
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getLicenseeBills()
  }, [])


  return (
    <Container>
      {
        userBills.map((bill, index) => (
          <Content>
            <Modal
              open={openCardSelectModal === index}
              onRequestClose={toggleCardSelectModal}
              modalWidth={500}
            >
              <BillPaymentDetails
                bill={bill}
                reloadUser={getLicenseeBills}
                onHandleCancel={toggleCardSelectModal}
                totalBillValue={Number(bill.bill_value) - Number(bill.paid_ammount)}
                closure_date={getDates(bill.referring_period, true) ?? new Date()}
                currency={currency?.code ?? currencyValue.BRL}
              />
            </Modal>

            <ClosureItem
              currency={currency?.code}
              bill={bill}
              closurePeriod={getDates(bill.referring_period, true)}
              acceptPayment={bill.transaction_code.length !== 7 && bill.payment_situation !== 'pago'}
              onHandlePayBill={() => setOpenCardSelectModal(index)}
              title={`${i18n.t("userProfile.previousClosure")} (${currency?.code
                }) ${bill.referring_period}`}
              description={`${i18n.t("userProfile.descriptions.closure")}`}
              period={`${formatDate(
                getDates(bill.referring_period, false) as Date
              )} - ${formatDate(getDates(bill.referring_period, true) as Date)}`}
              help={`${i18n.t("userProfile.descriptions.closurePopover")}`}
              value={(
                Number(bill.bill_value)
              ).toFixed(2)}
              onClick={() => {
                navigate({
                  pathname: `/${user.role.toLocaleLowerCase()}/orders/closure`,
                  search: `?from=${formatDate(
                    getDates(bill.referring_period, false) as Date
                  )}&to=${formatDate(getDates(bill.referring_period, true) as Date)}`,
                });
              }}
            />
          </Content>
        )
        )
      }
    </Container>
  )
}
